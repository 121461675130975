import { createRef, Fragment, useEffect, useState } from 'react';
import CollapsiblePanel from '@commercetools-uikit/collapsible-panel';
import { designTokens } from '@commercetools-uikit/design-system';
import Grid from '@commercetools-uikit/grid';
import Spacings from '@commercetools-uikit/spacings';
import Text from '@commercetools-uikit/text';
import ToggleInput from '@commercetools-uikit/toggle-input';
import { css } from '@emotion/react';
import { useIntl } from 'react-intl';
import { ConfirmationDialog } from '@commercetools-frontend/application-components';
import { COOKIE_CONSENT_LINKS } from '../../../constants/links';
import { useCookieConsent } from '../../react';
import {
  CookieDetails,
  createFunctionalCookieDetails,
  createPerformanceCookieDetails,
} from './cookie-details';
import { modalMessages } from './messages';

const View = {
  Primary: 'Primary',
  Secondary: 'Secondary',
  Tertiary: 'Tertiary',
} as const;

type NavigationLinkProps = {
  onClick: () => void;
  label: string;
};

/**
 * The design spec warranted text that _looks_ like a link, but isn't
 */
const NavigationLink = ({ onClick, label }: NavigationLinkProps) => {
  const underline = css`
    text-decoration: underline;
  `;

  return (
    <a
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      href=""
      css={underline}
    >
      {label}
    </a>
  );
};

type InformationLinkProps = {
  to: string;
  label: string;
};

const InformationLink = ({ to, label }: InformationLinkProps) => {
  const underline = css`
    text-decoration: underline;
  `;

  return (
    <a css={underline} href={to} target="_blank" rel="noopener noreferrer">
      {label}
    </a>
  );
};

type DetailLinkProps = {
  to: string;
  label: string;
};

const DetailLink = ({ to, label }: DetailLinkProps) => {
  const underline = css`
    text-decoration: underline;
  `;

  return (
    <a css={underline} href={to} target="_blank" rel="noopener noreferrer">
      <Text.Detail tone="primary">{label}</Text.Detail>
    </a>
  );
};

type CookieConsentBodyPrimaryProps = {
  togglePerformanceValue: boolean;
  toggleFunctionalValue: boolean;
  onTogglePerformance: (checked: boolean) => void;
  onToggleFunctional: (checked: boolean) => void;
  onChangeView: (view: keyof typeof View) => void;
  panelState: PanelState;
  setPanelState: (state: PanelState) => void;
};

const CookieConsentBodyPrimary = ({
  togglePerformanceValue,
  toggleFunctionalValue,
  onTogglePerformance,
  onToggleFunctional,
  onChangeView,
  panelState,
  setPanelState,
}: CookieConsentBodyPrimaryProps) => {
  const { formatMessage } = useIntl();

  // The outline of the input is cut off; we shift it to the left to compensate
  const bumpToggle = css`
    padding-right: 10px;
  `;

  return (
    <Spacings.Stack scale="xl">
      <Spacings.Stack scale="m">
        <Text.Body intlMessage={modalMessages.mainDescription} />
        <InformationLink
          label={formatMessage(modalMessages.privacyPolicyLink)}
          to={COOKIE_CONSENT_LINKS.PRIVACY_POLICY}
        />
      </Spacings.Stack>
      <Spacings.Stack scale="l">
        <CollapsiblePanel
          condensed
          header={formatMessage(modalMessages.functionalCookiesHeader)}
          headerControls={
            <div css={bumpToggle}>
              <ToggleInput
                isChecked={toggleFunctionalValue}
                onChange={(e) => onToggleFunctional(e.target.checked)}
                size="small"
              />
            </div>
          }
          isClosed={panelState.functionalCookiesClosed}
          onToggle={() =>
            setPanelState({
              ...panelState,
              functionalCookiesClosed: !panelState.functionalCookiesClosed,
            })
          }
          theme="light"
        >
          <Spacings.Stack scale="m">
            <Text.Body
              intlMessage={modalMessages.functionalCookiesDescription}
            />
            <NavigationLink
              label={formatMessage(modalMessages.cookieInformationLink)}
              onClick={() => onChangeView(View.Secondary)}
            />
          </Spacings.Stack>
        </CollapsiblePanel>
        <CollapsiblePanel
          condensed
          header={formatMessage(modalMessages.performanceCookiesHeader)}
          headerControls={
            <div css={bumpToggle}>
              <ToggleInput
                isChecked={togglePerformanceValue}
                onChange={(e) => onTogglePerformance(e.target.checked)}
                size="small"
              />
            </div>
          }
          isClosed={panelState.performanceCookiesClosed}
          onToggle={() =>
            setPanelState({
              ...panelState,
              performanceCookiesClosed: !panelState.performanceCookiesClosed,
            })
          }
          theme="light"
        >
          <Spacings.Stack scale="m">
            <Text.Body
              intlMessage={modalMessages.performanceCookiesDescription}
            />
            <NavigationLink
              label={formatMessage(modalMessages.cookieInformationLink)}
              onClick={() => onChangeView(View.Tertiary)}
            />
          </Spacings.Stack>
        </CollapsiblePanel>
      </Spacings.Stack>
    </Spacings.Stack>
  );
};

type PanelState = {
  functionalCookiesClosed: boolean;
  performanceCookiesClosed: boolean;
};

type CookieConsentBodyDetailsProps = {
  onChangeView: (view: keyof typeof View) => void;
  cookieDetails: Array<CookieDetails>;
};

const CookieConsentBodyDetails = ({
  onChangeView,
  cookieDetails,
}: CookieConsentBodyDetailsProps) => {
  const { formatMessage } = useIntl();
  const scrollRef = createRef<HTMLDivElement>();

  useEffect(() => {
    scrollRef.current?.scrollIntoView();
  }, [scrollRef]);

  return (
    <>
      <div
        ref={scrollRef}
        css={css`
          scroll-margin-top: ${designTokens.spacing40};
        `}
      />
      <Spacings.Stack scale="l">
        <NavigationLink
          label={formatMessage(modalMessages.navigateBackLink)}
          onClick={() => onChangeView(View.Primary)}
        />
        <Spacings.Stack scale="l">
          {cookieDetails.map((detail) => (
            <Grid
              key={detail.name.value}
              gridGap={designTokens.spacing40}
              gridRowGap="0px"
              gridTemplateColumns={`${designTokens.constraint2} 1fr`}
            >
              {Object.values(detail).map((value) => (
                <Fragment key={value.value}>
                  <Grid.Item>
                    <Text.Detail>{value.label}</Text.Detail>
                  </Grid.Item>
                  <Grid.Item>
                    {'to' in value ? (
                      <DetailLink label={value.value} to={value.to} />
                    ) : (
                      <Text.Detail>{value.value}</Text.Detail>
                    )}
                  </Grid.Item>
                </Fragment>
              ))}
            </Grid>
          ))}
        </Spacings.Stack>
      </Spacings.Stack>
    </>
  );
};

type ModalStateProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  setBannerClosed: (boolean: boolean) => void;
};

const CookieConsentModal = (modalProps: ModalStateProps) => {
  const intl = useIntl();
  const { setConsent } = useCookieConsent('performanceCookies');
  const [enablePerformanceCookies, setEnablePerformanceCookies] =
    useState(false);
  const [enableFunctionalCookies, setEnableFunctionalCookies] = useState(false);
  const [currentView, setCurrentView] = useState<keyof typeof View>(
    View.Primary
  );
  const [panelState, setPanelState] = useState<PanelState>({
    functionalCookiesClosed: true,
    performanceCookiesClosed: true,
  });

  const createConsentGroups = (
    functionalConsent: boolean,
    performanceConsent: boolean
  ) => ({
    functionalCookies: functionalConsent,
    performanceCookies: performanceConsent,
  });

  return (
    <ConfirmationDialog
      isOpen={modalProps.isModalOpen}
      labelPrimary={intl.formatMessage(modalMessages.primaryButtonLabel)}
      labelSecondary={intl.formatMessage(modalMessages.secondaryButtonLabel)}
      onCancel={() => {
        setConsent(
          createConsentGroups(enableFunctionalCookies, enablePerformanceCookies)
        );
        modalProps.setBannerClosed(true);
        modalProps.closeModal();
      }}
      onClose={modalProps.closeModal}
      onConfirm={() => {
        setConsent(createConsentGroups(true, true));
        modalProps.setBannerClosed(true);
        modalProps.closeModal();
      }}
      title={intl.formatMessage(modalMessages.title)}
      zIndex={1000000}
    >
      {currentView === View.Primary && (
        <CookieConsentBodyPrimary
          onChangeView={(view) => setCurrentView(view)}
          onToggleFunctional={(value) => setEnableFunctionalCookies(value)}
          onTogglePerformance={(value) => setEnablePerformanceCookies(value)}
          panelState={panelState}
          setPanelState={(state) => setPanelState(state)}
          toggleFunctionalValue={enableFunctionalCookies}
          togglePerformanceValue={enablePerformanceCookies}
        />
      )}
      {currentView === View.Secondary && (
        <CookieConsentBodyDetails
          cookieDetails={createFunctionalCookieDetails(intl)}
          onChangeView={(view) => setCurrentView(view)}
        />
      )}
      {currentView === View.Tertiary && (
        <CookieConsentBodyDetails
          cookieDetails={createPerformanceCookieDetails(intl)}
          onChangeView={(view) => setCurrentView(view)}
        />
      )}
    </ConfirmationDialog>
  );
};

export default CookieConsentModal;
