import { Component, type ReactNode } from 'react';

type Props = {
  children: ReactNode;
};
type State = {
  hasError: boolean;
};

class ErrorBoundary extends Component<Props, State> {
  static getDerivedStateFromError(/* error */) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  override state = {
    hasError: false,
  };

  override render() {
    if (this.state.hasError) {
      return null;
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
