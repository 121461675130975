import { useEffect } from 'react';

declare global {
  interface Window {
    userGuiding: Record<string, unknown> & {
      identify: (params: Record<string, string | null | undefined>) => void;
    };
  }
}

type Nullable<T> = T | null | undefined;

export type TUserGuidingProperties = {
  userId?: Nullable<string>;
  environment?: Nullable<string>;
  projectKey?: Nullable<string>;
  userBusinessRole?: Nullable<string>;
  userLocale?: Nullable<string>;
};

type TTrackingArgs = {
  properties: TUserGuidingProperties;
  disable?: boolean;
  additionalUserVars?: Record<string, unknown>;
};

const defaultTrackingArgs: TTrackingArgs = {
  properties: {},
  disable: false,
  additionalUserVars: undefined,
};

/**
 * useTrackingEffect: this method sends specific userdata to UserGuiding for user tracking and segmentation purposes, data sent by default are `uid`, `projectKey`, `environment`, `businessRole`, and `locale`
 *
 * @param {object} properties data to send to UserGuiding
 * @param {boolean} disable disables sending user data to UserGuiding if set to `true`
 * @param {object} additionalUserVars any additional user data to send to UserGuiding
 */
function useTrackingEffect({
  properties,
  disable = false,
  additionalUserVars,
}: TTrackingArgs = defaultTrackingArgs) {
  useEffect(() => {
    // if the UserGuiding `identify` method is not set on `window`, we cannot call it
    if (!window.userGuiding?.identify || disable) {
      return;
    }

    window.userGuiding.identify({
      uid: properties.userId,
      businessRole: properties.userBusinessRole,
      locale: properties.userLocale,
      environment: properties.environment,
      projectKey: properties.projectKey,
      ...additionalUserVars,
    });
  }, [disable, additionalUserVars, properties]);

  return null;
}

export { useTrackingEffect };
