import { useEffect, useState } from 'react';
import { designTokens } from '@commercetools-uikit/design-system';
import PrimaryButton from '@commercetools-uikit/primary-button';
import Spacings from '@commercetools-uikit/spacings';
import Text from '@commercetools-uikit/text';
import { css } from '@emotion/react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useModalState } from '@commercetools-frontend/application-components';
import { COOKIE_CONSENT_LINKS } from '../../../constants/links';
import { unsetConsentCookie } from '../../core';
import { useCookieConsent, CookieConsentModal } from '../../react';
import messages from './messages';

type ConsentBannerProps = {
  skipConsent: boolean;
};

const BANNER_HEIGHT = 64;

/**
 * Define a fixed date to determine the validity of consent cookies. Update as required.
 *
 * Cookies set before this date are considered invalid due to new cookie regulations or changes.
 */
const COOKIE_REVOCATION_DATE =
  'Wed May 01 2024 12:00:00 GMT-0400 (Eastern Daylight Time)';

const CookieConsentBanner = (bannerProps: ConsentBannerProps) => {
  useEffect(() => {
    // Revoke cookies prior to a certain date to ensure user preferences are up-to-date with cookie configuration
    unsetConsentCookie(new Date(COOKIE_REVOCATION_DATE));
  }, []);

  // Hide the banner once the accept button is clicked, until we can subscribe to the cookie
  const [bannerClosed, setBannerClosed] = useState(false);
  const { closeModal, openModal, isModalOpen } = useModalState();
  const { formatMessage } = useIntl();
  const { setConsent, givenConsent: hasGivenPerformanceCookieConsent } =
    useCookieConsent('performanceCookies', {
      skipConsent: bannerProps.skipConsent,
    });
  const { givenConsent: hasGivenFunctionalCookieConsent } = useCookieConsent(
    'functionalCookies',
    { skipConsent: bannerProps.skipConsent }
  );

  const createConsentGroups = () => ({
    functionalCookies: true,
    performanceCookies: true,
  });

  const underline = css`
    text-decoration: underline;
    color: ${designTokens.colorPrimary98};
  `;

  if (
    bannerClosed ||
    hasGivenFunctionalCookieConsent ||
    hasGivenPerformanceCookieConsent
  )
    return null;

  return (
    // TODO: use lower z-index, prefer ranges per use-case
    <div
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
        z-index: 9999;
      `}
    >
      <div
        css={css`
          position: sticky;
          top: calc(100% - ${BANNER_HEIGHT}px - ${designTokens.spacingXl});
          display: flex;
          flex-direction: column;
          align-items: center;
          z-index: 9999;
          width: 100%;
          pointer-events: auto;
        `}
      >
        <div
          css={css`
            background-color: ${designTokens.colorAccent10};
            border-radius: ${designTokens.borderRadius4};
            box-shadow: ${designTokens.shadow8};
            margin: 0 ${designTokens.spacingXl};
            padding: ${designTokens.spacingM} ${designTokens.spacingL};
            width: fit-content;
          `}
        >
          <Spacings.Inline alignItems="center" scale="xl">
            <Text.Body tone="inverted">
              <FormattedMessage
                {...messages.bannerMessage}
                values={{
                  privacyLink: (
                    <a
                      css={underline}
                      href={COOKIE_CONSENT_LINKS.PRIVACY_POLICY}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {formatMessage(messages.privacyPolicyLink)}
                    </a>
                  ),
                  modalLink: (
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        openModal();
                      }}
                      css={underline}
                      href=""
                    >
                      {formatMessage(messages.bannerCookiesModalLink)}
                    </a>
                  ),
                }}
              />
            </Text.Body>
            <PrimaryButton
              label={formatMessage(messages.buttonLabel)}
              onClick={() => {
                setConsent(createConsentGroups());
                setBannerClosed(true);
              }}
              size="medium"
            />
          </Spacings.Inline>
        </div>
        <CookieConsentModal
          closeModal={closeModal}
          isModalOpen={isModalOpen}
          setBannerClosed={setBannerClosed}
        />
      </div>
    </div>
  );
};

CookieConsentBanner.displayName = 'CookieConsentBanner';

export { CookieConsentBanner };
