import { KeyObject } from 'crypto';
import { useEffect } from 'react';
import {
  FullStory,
  isInitialized as isFullStoryInitialized,
} from '@fullstory/browser';

type TTrackingArgs = {
  properties: TFullStoryProperties;
  disable?: boolean;
  additionalUserVars?: KeyObject;
};

type Nullable<T> = T | null | undefined;

export type TFullStoryProperties = {
  userId?: Nullable<string>;
  environment?: Nullable<string>;
  projectKey?: Nullable<string>;
  isProductionProject?: Nullable<boolean>;
  organizationId?: Nullable<string>;
  userBusinessRole?: Nullable<string>;
  userLocale?: Nullable<string>;
  isUserAdminOfCurrentProject?: Nullable<boolean>;
};

const defaultTrackingArgs: TTrackingArgs = {
  properties: {},
  disable: false,
  additionalUserVars: undefined,
};

function useTrackingEffect({
  properties,
  disable,
  additionalUserVars,
}: TTrackingArgs = defaultTrackingArgs) {
  useEffect(() => {
    // These values are related to segmenting users in the FS dashboard
    function getUserRole(): string {
      switch (properties.isUserAdminOfCurrentProject) {
        case true:
          return 'admin';
        case false:
          return 'regular';
        default:
          return 'unknown';
      }
    }

    // It's not safe to invoke any FullStory SDK methods.
    if (!isFullStoryInitialized() || disable) {
      return;
    }

    if (!properties.userId) {
      FullStory('setIdentity', { anonymous: true });
    } else {
      FullStory('setIdentity', {
        uid: properties.userId,
        properties: {
          ...properties,
          userRole: getUserRole(),
          ...additionalUserVars,
        },
      });
    }
  }, [disable, additionalUserVars, properties]);

  return null;
}

export { useTrackingEffect };
